import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axiosAPI from "../../axiosAPI";
import { useCheckUserEntitiesPermissions } from "../../hooks/useCheckUserEntitiesPermissions";
import UserPermissionCheckbox from "../Checkboxes/UserPermissionCheckbox";

export default function UserPermissionsModal(props) {
  const { register, handleSubmit, reset } = useForm();
  const [modalShow, setModalShow] = React.useState(false);
  const { entitiesPermissions, isLoading, isFetching } =
    useCheckUserEntitiesPermissions(props.id);
  const queryClient = useQueryClient();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [permissions, setPermissions] = useState({
    timesheet: false,
    chat: false,
    calcjuris: false,
    quantiko: false,
    notifications: false
  });

  const onSubmitUpdateUserPermission = (values) => {
    axiosAPI
      .post("user/permissions/entities", values)
      .then((data) => {
        if (data.status === 200) {
          toast.success("Permissões atualizadas", {
            position: toast.POSITION.TOP_CENTER,
          });
          queryClient.invalidateQueries({ queryKey: ["employees", "list"] });
        }
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  };

  useEffect(() => {
    axiosAPI(`/user/permissions/entities/${user.id}`).then(({ data }) => {
      setPermissions(data.permissions);
    });
  }, [user]);

  return (
    <>
      <button onClick={() => setModalShow(true)}>
        <i class="bi bi-gear"></i>
      </button>

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="xs"
          centered
          scrollable
        >
          <Modal.Header closeButton className="modalGradient">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="text-white"
            >
              <i class="bi bi-person-workspace"></i>&nbsp; Permissões
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="fs-5 fw-bold">{props.name}</p>
            <p className="fs-7 fw-bold">Ações Permitidas:</p>

            {isFetching ? (
              <div class="d-flex justify-content-center m-5">
                <Spinner animation="border" variant="warning" />
              </div>
            ) : (
              <Form onSubmit={handleSubmit(onSubmitUpdateUserPermission)}>
                <div styles={{ maxHeight: "100px", overflowY: "scroll" }}>
                  <p className="fs-9 ">Processos:</p>
                  <Form.Group>
                    <UserPermissionCheckbox
                      register={register}
                      entity="lawsuit"
                      entitiesPermissions={entitiesPermissions}
                      permissions={["Acessar"]}
                    />
                  </Form.Group>
                  <hr />

                  <p className="fs-9 ">Clientes:</p>
                  <Form.Group>
                    <UserPermissionCheckbox
                      register={register}
                      entity="client"
                      entitiesPermissions={entitiesPermissions}
                      permissions={["Acessar"]}
                    />
                  </Form.Group>
                  <hr />

                  <p className="fs-9 ">Atendimento:</p>
                  <Form.Group>
                    <UserPermissionCheckbox
                      register={register}
                      entity="support_case"
                      entitiesPermissions={entitiesPermissions}
                      permissions={["Acessar"]}
                    />
                  </Form.Group>
                  <hr />

                  <p className="fs-9 ">Equipe:</p>
                  <Form.Group>
                    <UserPermissionCheckbox
                      register={register}
                      entity="employee"
                      entitiesPermissions={entitiesPermissions}
                      permissions={["Acessar"]}
                    />
                  </Form.Group>
                  <hr />

                  {permissions.timesheet ? (
                    <>
                      <p className="fs-9 ">Timesheet:</p>
                      <Form.Group>
                        <UserPermissionCheckbox
                          register={register}
                          entity="timesheet"
                          entitiesPermissions={entitiesPermissions}
                          permissions={["Acessar"]}
                        />
                      </Form.Group>
                      <hr />
                    </>
                  ) : null}

                  {permissions.chat ? (
                    <>
                      <p className="fs-9 ">Chat:</p>
                      <Form.Group>
                        <UserPermissionCheckbox
                          register={register}
                          entity="chat"
                          entitiesPermissions={entitiesPermissions}
                          permissions={["Acessar"]}
                        />
                      </Form.Group>
                      <hr />
                    </>
                  ) : null}

                  {permissions.calcjuris ? (
                    <>
                      <p className="fs-9 ">CalcJuris:</p>
                      <Form.Group>
                        <UserPermissionCheckbox
                          register={register}
                          entity="calcjuris"
                          entitiesPermissions={entitiesPermissions}
                          permissions={["Acessar"]}
                        />
                      </Form.Group>
                      <hr />
                    </>
                  ) : null}

                  {permissions.quantiko ? (
                    <>
                      <p className="fs-9 ">QuantiKo:</p>
                      <Form.Group>
                        <UserPermissionCheckbox
                          register={register}
                          entity="quantiko"
                          entitiesPermissions={entitiesPermissions}
                          permissions={["Acessar"]}
                        />
                      </Form.Group>
                      <hr />
                    </>
                  ) : null}

                  {permissions.notifications ? (
                    <>
                      <p className="fs-9 ">Notificações:</p>
                      <Form.Group>
                        <UserPermissionCheckbox
                          register={register}
                          entity="notifications"
                          entitiesPermissions={entitiesPermissions}
                          permissions={["Acessar"]}
                        />
                      </Form.Group>
                      <hr />
                    </>
                  ) : null}

                  <p className="fs-9 ">Calendário:</p>
                  <Form.Group>
                    <UserPermissionCheckbox
                      register={register}
                      entity="calendar"
                      entitiesPermissions={entitiesPermissions}
                      permissions={["Acessar"]}
                    />
                  </Form.Group>
                  <hr />
                  {/* <p className="fs-9 ">Financeiro:</p>
                  <Form.Group>
                    <UserPermissionCheckbox
                      register={register}
                      entity="finance"
                      entitiesPermissions={entitiesPermissions}
                      permissions={["Acessar"]}
                    />
                  </Form.Group>
                  <hr /> */}

                  <p className="fs-9 ">Favoritos:</p>
                  <Form.Group>
                    <UserPermissionCheckbox
                      register={register}
                      entity="favorite"
                      entitiesPermissions={entitiesPermissions}
                      permissions={["Acessar"]}
                    />
                  </Form.Group>
                  <hr />

                  <p className="fs-9 ">Arquivos:</p>
                  <Form.Group>
                    <UserPermissionCheckbox
                      register={register}
                      entity="file"
                      entitiesPermissions={entitiesPermissions}
                      permissions={["Acessar"]}
                    />
                  </Form.Group>
                </div>

                <Form.Control
                  className="form-control"
                  type="hidden"
                  value={props.id}
                  name="user_id"
                  {...register("user_id")}
                />
                <div className="w-100">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                    <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
                      <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-4">
                        <Button
                          className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                          onClick={() => setModalShow(false)}
                          type="button"
                          style={{ backgroundColor: "#DAA520" }}
                        >
                          Cancelar
                        </Button>
                      </div>
                      <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-4">
                        <Button
                          className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                          type="submit"
                          style={{ backgroundColor: "#DAA520" }}
                        >
                          <i className="bi bi-funnel"></i>&nbsp; Salvar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
