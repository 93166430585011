import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axiosAPI from "../../axiosAPI";
import NotificationsContext from "../../contexts/Notifications/NotificationsContext";
import { SelectClients } from "../Selects/SelectClients";
import SelectEmployeesUsers from "../Selects/SelectEmployeesUsers";

export default function CalendarEventCommitmentModal(props) {
  const { register, handleSubmit, reset } = useForm();
  const { getNotificationMutation } = useContext(NotificationsContext);
  const [commitmentOwner, setCommitmentOwner] = useState(1);
  const [clientId, setClientId] = useState();
  const queryClient = useQueryClient();

  const onSubmitEditEvent = (values) => {
    axiosAPI
      .patch(`/commitments/${values.commitment_id}`, values)
      .then((data) => {
        if (data.status === 201) {
          queryClient.invalidateQueries({ queryKey: ["commitments"] });
          toast.success("Evento editado!");
          getNotificationMutation();
        }
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  };

  useEffect(() => {
    if (props) {
      setCommitmentOwner(
        props.event?.user_id == JSON.parse(localStorage.getItem("user")).id
          ? 1
          : 2
      );
      setClientId(props.event?.client_id);
      reset({
        commitment_title: props.event?.title,
        commitment_type_id: props.event?.commitment_type_id,
        commitment_start_date: props.event?.start
          ? format(props.event?.start, "yyyy-MM-dd")
          : "",
        commitment_start_time: props.event?.start
          ? format(props.event?.start, "HH:mm")
          : "",
        commitment_end_date: props.event?.end
          ? format(props.event?.end, "yyyy-MM-dd")
          : "",
        commitment_end_time: props.event?.end
          ? format(props.event?.end, "HH:mm")
          : "",
        client_id: props.event?.client_id,
        user_id: props.event?.user_id,
        obs_general: props.event?.obs_general,
        notification_lawyer_time: props.event?.notification_lawyer_time,
      });
    }
  }, [props]);

  function completeCommitment(commitmentId) {
    axiosAPI
      .patch(`/commitments/${commitmentId}`, {
        commitment_status: "done",
        obs_general: props.event?.obs_general,
        notification_lawyer_time: props.event?.notification_lawyer_time,
      })
      .then((data) => {
        queryClient.invalidateQueries({ queryKey: ["commitments"] });
        toast.success("Compromisso marcado como concluído!", {
          position: "bottom-right",
        });
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  }

  function revertCompleteCommitment(commitmentId) {
    axiosAPI
      .patch(`/commitments/${commitmentId}`, {
        commitment_status: "open",
        obs_general: props.event?.obs_general,
        notification_lawyer_time: props.event?.notification_lawyer_time,
      })
      .then((data) => {
        queryClient.invalidateQueries({ queryKey: ["commitments"] });
        toast.success("Compromisso revertido!", {
          position: "bottom-right",
        });
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  }

  function deleteCommitment(commitmentId) {
    axiosAPI
      .delete(`/commitments/${commitmentId}`)
      .then((data) => {
        queryClient.invalidateQueries({ queryKey: ["commitments"] });
        toast.success("Compromisso removido!", {
          position: "bottom-right",
        });

        getNotificationMutation();
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));;
  }

  return (
    <Modal
      show={props.calendarEventCommitmentModalShow}
      onHide={() => props.setCalendarEventCommitmentModalShow(false)}
      size="lg"
      centered
      scrollable
    >
      <Modal.Header closeButton className="modalGradient">
        <Modal.Title id="contained-modal-title-vcenter" className="text-white">
          <i className="bi bi-person-workspace"></i>&nbsp; Evento{" "}
          {props.event?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.calendarEventCommitmentModalShow ? (
          <>
            <Row>
              <Col>
                <div
                  className="d-flex align-items-center justify-content-end"
                  style={{ gap: "15px" }}
                >
                  <Button
                    onClick={() => deleteCommitment(props.event?.id)}
                    variant="danger"
                  >
                    Deletar
                  </Button>

                  {props.event?.status == "done" && (
                    <Button
                      onClick={() => revertCompleteCommitment(props.event?.id)}
                      variant="warning"
                    >
                      Não Concluído
                    </Button>
                  )}

                  {props.event?.status == "open" && (
                    <Button
                      onClick={() => completeCommitment(props.event?.id)}
                      variant="success"
                    >
                      Concluído
                    </Button>
                  )}
                </div>
              </Col>
            </Row>

            <Form onSubmit={handleSubmit(onSubmitEditEvent)}>
              <Form.Group>
                <FloatingLabel label="Título">
                  <Form.Control
                    className="form-control"
                    name="commitment_title"
                    {...register("commitment_title")}
                  />
                </FloatingLabel>
              </Form.Group>
              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Esse compromisso é:"
                  >
                    <Form.Select
                      onChange={(e) => setCommitmentOwner(e.target.value)}
                      className="form-select form-control"
                      name="commitment_owner"
                    >
                      <option value="1">Para Mim</option>
                      <option value="2">Para um funcionário</option>
                    </Form.Select>
                  </FloatingLabel>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  {commitmentOwner == 2 && (
                    <SelectEmployeesUsers register={register} />
                  )}
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <FloatingLabel label="Data do compromisso">
                    <Form.Control
                      type="date"
                      className="form-control"
                      name="commitment_start_date"
                      {...register("commitment_start_date")}
                      required
                    />
                  </FloatingLabel>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <FloatingLabel label="Inicio do compromisso">
                    <Form.Control
                      type="time"
                      className="form-control"
                      name="commitment_start_time"
                      {...register("commitment_start_time")}
                      placeholder="Digite a hora inicial"
                      required
                    />
                  </FloatingLabel>
                </div>
              </div>

              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <FloatingLabel label="Data do compromisso">
                    <Form.Control
                      type="date"
                      className="form-control"
                      name="commitment_end_date"
                      {...register("commitment_end_date")}
                      required
                    />
                  </FloatingLabel>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <FloatingLabel label="Fim do compromisso">
                    <Form.Control
                      type="time"
                      className="form-control"
                      name="commitment_end_time"
                      {...register("commitment_end_time")}
                      placeholder="Digite a hora inicial"
                      required
                    />
                  </FloatingLabel>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <SelectClients register={register} />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <FloatingLabel label="Qual o compromisso">
                    <Form.Select
                      id="commitment_type_id"
                      className="form-select form-control"
                      {...register("commitment_type_id")}
                      name="commitment_type_id"
                      aria-label="commitment_type_id"
                    >
                      {props.types.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.title}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </div>
              </div>

              {/* <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <FloatingLabel label="Notificar advogado">
                    <Form.Select
                      id="notificacao_advogado"
                      className="form-select form-control"
                      name="notificacao_advogado"
                      {...register("notification_lawyer_time")}
                      aria-label="notificacao_advogado"
                    >
                      {mockNotificationLawyerTime().map((n) => (
                        <option key={n.value} value={n.value}>
                          {n.label}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </div>
              </div> */}

              <div className="d-flex">
                <Form.Control
                  id="obs_geral1"
                  as="textarea"
                  placeholder="Observações gerais"
                  style={{ height: "100px" }}
                  name="obs_general"
                  {...register("obs_general")}
                />
              </div>

              <Form.Control
                className="form-control"
                type="hidden"
                value={props.event?.id}
                name="commitment_id"
                {...register("commitment_id")}
              />

              <div className="w-100">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                  <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
                    <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-4">
                      <Button
                        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                        onClick={() =>
                          props.setCalendarEventCommitmentModalShow(false)
                        }
                        type="button"
                        style={{ backgroundColor: "#DAA520" }}
                      >
                        Fechar
                      </Button>
                    </div>
                    <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-4">
                      <Button
                        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                        type="submit"
                        style={{ backgroundColor: "#DAA520" }}
                      >
                        <i className="bi bi-funnel"></i>&nbsp; Salvar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </>
        ) : (
          <>carregando</>
        )}
      </Modal.Body>
    </Modal>
  );
}
