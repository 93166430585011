import { useQueryClient } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axiosAPI from "../../../axiosAPI";
import NotificationsContext from "../../../contexts/Notifications/NotificationsContext";

import { SelectClients } from "../../../components/Selects/SelectClients";
import SelectEmployeesUsers from "../../../components/Selects/SelectEmployeesUsers";


export default function CriarCompromisso(props) {
  const { getNotificationMutation } = useContext(NotificationsContext);
  const [modalShow1, setModalShow1] = useState(false);
  const [commitmentOwner, setCommitmentOwner] = useState(1);
  const queryClient = useQueryClient();

  const defaultValues = {
    commitment_start_date: "",
    commitment_end_date: "",
    commitment_title: "",
    commitment_type_id: "",
    user_id: "",
    client_id: "",
    obs_general: "",
  };

  const { register, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmitCommitment = (values) => {
    axiosAPI
      .post("/commitments", values)
      .then((result) => {
        if (result.status == 200) {
          getNotificationMutation();
          queryClient.invalidateQueries({ queryKey: ["commitments"] });
          reset(defaultValues, {
            keepValues: false,
          });
          toast.success("Compromisso cadastrado.", {
            position: "bottom-right",
          });
          setModalShow1(false);
        }
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));;
  };

  return (
    <>
      <Button
        className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
        style={{ backgroundColor: "#DAA520" }}
        onClick={() => setModalShow1(true)}
      >
        <i className="bi bi-card-checklist"></i>&nbsp; Criar compromisso
      </Button>

      <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton className="modalGradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            <i className="bi bi-card-checklist"></i>&nbsp; Criar compromisso
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#edebe9" }}>
          <h5>Preencha os campos para criar um novo compromisso</h5>
          <Form onSubmit={handleSubmit(onSubmitCommitment)}>
            <Form.Group>
              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Qual o compromisso"
                  >
                    <Form.Select
                      id="commitment_type_id"
                      className="form-select form-control"
                      {...register("commitment_type_id")}
                      name="commitment_type_id"
                      aria-label="commitment_type_id"
                    >
                      {props.types.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.title}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Dê um título ao compromisso"
                  >
                    <Form.Control
                      type="text"
                      className="form-control"
                      name="commitment_title"
                      {...register("commitment_title")}
                      placeholder="Insira um compromisso"
                    />
                  </FloatingLabel>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Esse compromisso é:"
                  >
                    <Form.Select
                      onChange={(e) => setCommitmentOwner(e.target.value)}
                      className="form-select form-control"
                      name="commitment_owner"
                    >
                      <option value="1">Para Mim</option>
                      <option value="2">Para um funcionário</option>
                    </Form.Select>
                  </FloatingLabel>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  {commitmentOwner == 2 && (
                    <SelectEmployeesUsers register={register} />
                  )}
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Data do compromisso"
                  >
                    <Form.Control
                      type="date"
                      className="form-control"
                      name="commitment_start_date"
                      {...register("commitment_start_date")}
                      required
                    />
                  </FloatingLabel>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Inicio do compromisso"
                  >
                    <Form.Control
                      type="time"
                      className="form-control"
                      name="commitment_start_time"
                      {...register("commitment_start_time")}
                      placeholder="Digite a hora inicial"
                      required
                    />
                  </FloatingLabel>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Data do compromisso"
                  >
                    <Form.Control
                      type="date"
                      className="form-control"
                      name="commitment_end_date"
                      {...register("commitment_end_date")}
                      required
                    />
                  </FloatingLabel>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fim do compromisso"
                  >
                    <Form.Control
                      type="time"
                      className="form-control"
                      name="commitment_end_time"
                      {...register("commitment_end_time")}
                      placeholder="Digite a hora inicial"
                      required
                    />
                  </FloatingLabel>
                </div>
              </div>

              <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                  <SelectClients register={register} />
                </div>

                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Notificar advogado"
                  >
                    <Form.Select
                      id="notificacao_advogado"
                      className="form-select form-control"
                      name="notificacao_advogado"
                      {...register("notification_lawyer_time")}
                      aria-label="notificacao_advogado"
                    >
                      {mockNotificationLawyerTime().map((n) => (
                        <option key={n.value} value={n.value}>
                          {n.label}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </div> */}
              </div>
              <div className="d-flex">
                <Form.Control
                  id="obs_geral1"
                  as="textarea"
                  placeholder="Observações gerais"
                  style={{ height: "100px" }}
                  name="obs_general"
                  {...register("obs_general")}
                />
              </div>
            </Form.Group>
            <Modal.Footer style={{ backgroundColor: "#edebe9" }}>
              <div className="w-100">
                <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                  <Button
                    type="submit"
                    className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor"
                    style={{ backgroundColor: "#DAA520" }}
                  >
                    <i className="bi bi-hdd-network"></i>&nbsp; Cadastrar
                    compromisso
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
