import React from "react";

import { Button, Modal } from "react-bootstrap";

import IconCoroa from "../../assets/coroa.png";

function ModalFirstLoadPresentation({
  show,
  update,
  permissions,
}) {
  return (
    <Modal show={show()} onHide={() => update()} size="xs" scrollable>
      <Modal.Header closeButton className="modalGradient">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-white"
        ></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {permissions.calcjuris && permissions.quantiko ? (
          <h2 className="title-modal-tools text-center">
            Aproveite as ferramentas do <span>PLANO PREMIUM.</span>
          </h2>
        ) : (
          <h2 className="title-modal-tools text-center">
            Já conhece as ferramentas do <span>PLANO PREMIUM?</span>
          </h2>
        )}

        <div className="container">
          <div className="content-btns-modal-tools">
            <div className="btn-modal-tools">
              <a
                href="https://www.calcjuris.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="d-flex">
                  <Button
                    className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-white"
                    style={{ backgroundColor: "#222222" }}
                  >
                    <img
                      src={IconCoroa}
                      alt="icon premium"
                      id="icon-premium"
                      width="18"
                      className="text-white"
                    />
                    &nbsp; CalcJuris
                  </Button>
                </div>
              </a>
              <div className="modal-tools-information">
                <i className="bi bi-info-circle"></i>
                &nbsp;
                <p>
                  Possibilita ao advogado atualização de valores por diversos
                  índices à disposição, e ainda com cálculo de juros de mora e
                  juros remuneratórios, custas, multas e honorários em seus
                  processos judiciais.
                </p>
              </div>
            </div>

            <div className="btn-modal-tools">
              <a
                href="https://www.quantiko.net.br/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="d-flex">
                  <Button
                    className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-white"
                    style={{ backgroundColor: "#222222" }}
                    onClick={() => {}}
                  >
                    <img
                      src={IconCoroa}
                      alt="icon premium"
                      id="icon-premium"
                      width="18"
                      className="text-white"
                    />
                    &nbsp; QuantiKo
                  </Button>
                </div>
              </a>
              <div className="modal-tools-information">
                <i className="bi bi-info-circle"></i>
                &nbsp;
                <p>
                  Oferece ao advogado várias possibilidades de utilização em
                  processos trabalhistas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalFirstLoadPresentation;
